import { Component } from '@angular/core';

@Component({
  selector: 'app-qa-testing-developement',
  templateUrl: './qa-testing-developement.component.html',
  styleUrls: ['./qa-testing-developement.component.scss']
})
export class QaTestingDevelopementComponent {

}
