import { Component } from '@angular/core';

@Component({
  selector: 'app-bashit',
  templateUrl: './bashit.component.html',
  styleUrls: ['./bashit.component.scss']
})
export class BashitComponent {

}
