import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './components/pages/contact/contact.component';

import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';

import { ProjectsTwoComponent } from './components/pages/projects-two/projects-two.component';
import { ProjectsOneComponent } from './components/pages/projects-one/projects-one.component';

import { AboutComponent } from './components/pages/about/about.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';

import { MobileAppDevelopmentComponent } from './components/pages/mobile-app-development/mobile-app-development.component';
import { WebAppDevelopmentComponent } from './components/pages/web-app-development/web-app-development.component';
import { WebsiteDevelopementComponent } from './components/pages/website-developement/website-developement.component';
import { EcommerceDevelopmentComponent } from './components/pages/ecommerce-development/ecommerce-development.component';
import { QaTestingDevelopementComponent } from './components/pages/qa-testing-developement/qa-testing-developement.component';
import { ManagedItSourcingComponent } from './components/pages/managed-it-sourcing/managed-it-sourcing.component';
import { ItStaffAugmentationComponent } from './components/pages/it-staff-augmentation/it-staff-augmentation.component';
import { MvpDevelopmentComponent } from './components/pages/mvp-development/mvp-development.component';
import { DevOpsComponent } from './components/pages/dev-ops/dev-ops.component';
import { QAaaSComponent } from './components/pages/qaaa-s/qaaa-s.component';
import { IosNativeComponent } from './components/pages/ios-native/ios-native.component';
import { MernStackComponent } from './components/pages/mern-stack/mern-stack.component';
import { AndroidNativeComponent } from './components/pages/android-native/android-native.component';
import { MeanStackComponent } from './components/pages/mean-stack/mean-stack.component';
import { PhpDeveloperComponent } from './components/pages/php-developer/php-developer.component';
import { LaravelComponent } from './components/pages/laravel/laravel.component';
import { NodejsDeveloperComponent } from './components/pages/nodejs-developer/nodejs-developer.component';
import { ProjectDetailsComponent } from './components/pages/project-details/project-details.component';
import { CareerComponent } from './components/pages/career/career.component';
import { BashitComponent } from './components/pages/bashit/bashit.component';
const routes: Routes = [
    {path: '', component: HomeThreeComponent},
    
    {path: 'home-three', component: HomeThreeComponent},
    {path: 'about', component: AboutComponent},

    {path: 'portfolio', component: ProjectsOneComponent},
    {path: 'projects-two', component: ProjectsTwoComponent},
 
    {path: 'project-details', component: ProjectDetailsComponent },
    {path:'project-details/Temple-tourism',component:ProjectDetailsComponent},
    {path:'project-details/upaj-badao',component:ProjectDetailsComponent},
    {path:'project-details/Gigforce',component:ProjectDetailsComponent},
    {path:'project-details/pay-docs',component:ProjectDetailsComponent},
    {path:'project-details/monitor-fish',component:ProjectDetailsComponent},
    {path:'project-details/level-up',component:ProjectDetailsComponent},
    {path:'project-details/goody',component:ProjectDetailsComponent},
    {path:'project-details/madhatv',component:ProjectDetailsComponent},
    {path:'project-details/edwisely',component:ProjectDetailsComponent},

    {path: 'terms-conditions', component: TermsConditionsComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
   
    {path: 'contact', component: ContactComponent},
    {path:'mobile-app-development',component:MobileAppDevelopmentComponent},
    {path:'web-app-development',component:WebAppDevelopmentComponent},
    {path:'backend-development',component:WebsiteDevelopementComponent},
    {path:'customized-solutions',component:EcommerceDevelopmentComponent},
    {path:'seo-aso',component:QaTestingDevelopementComponent},
    {path:'business-consultancy',component:ManagedItSourcingComponent},
    {path:'raas',component:ItStaffAugmentationComponent},
    {path:'offshore-development-center',component:MvpDevelopmentComponent},
    {path:'devops',component:DevOpsComponent},
    {path:'qaas',component:QAaaSComponent},
    {path:'android-native',component:AndroidNativeComponent},
    {path:'ios-native',component:IosNativeComponent},
    {path:'mern-stack',component:MernStackComponent},
    {path:'mean-stack',component:MeanStackComponent},
    {path:'php-developer',component:PhpDeveloperComponent},
    {path:'python',component:LaravelComponent},
    {path:'career',component:CareerComponent},
    {path:'nodejs-developer',component:NodejsDeveloperComponent},
    {path:'bashit',component:BashitComponent},
    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}