import { Component } from '@angular/core';

@Component({
  selector: 'app-nodejs-developer',
  templateUrl: './nodejs-developer.component.html',
  styleUrls: ['./nodejs-developer.component.scss']
})
export class NodejsDeveloperComponent {

}
