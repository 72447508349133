import { Component } from '@angular/core';

@Component({
  selector: 'app-ios-native',
  templateUrl: './ios-native.component.html',
  styleUrls: ['./ios-native.component.scss']
})
export class IosNativeComponent {

}
