import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {
  currentrouter:string;
  title:string;
  templeTourism:boolean=false;
  upajBadao:boolean=false;
  gigForce:boolean=false;
  paydocs:boolean=false;
  monitorFish:boolean=false;
  levelUp:boolean= false;
  posSolution:boolean = false;
  goody:boolean = false;
  madhatv:boolean = false;
  edwisely:boolean = false;
  description:string;
  constructor(private router:Router) {
    this.currentrouter = router.url;
    console.log(this.currentrouter.charAt(0),'current router');
    
    
   }

  ngOnInit() {
    this.routerdetails();
  }
  routerdetails(){
    if(this.currentrouter == '/project-details/Temple-tourism'){
       this.title = 'Temple Tourism'
       this.description = 'Temple Tourisms provides more than 10000+ Tourist places in Tamil Nadu exclusive special tours and trips to exotic destinations for newlyweds. Besides, we follow a continuous research for temples, tourist places and a range of travel up gradations that helps us serve the best to our users.'
       this.templeTourism = true;
    }else if(this.currentrouter == '/project-details/upaj-badao'){
      this.title = 'Upaj Badao'
      this.description = 'UpajBadao is a mobile app which enables small landholding farmers to maximise their vegetable crop yield at an almost zero budget. The App is location based and presently activated for Raigad district (Maharashtra state), India.'
      this.upajBadao = true;
    }else if(this.currentrouter == '/project-details/Gigforce'){
      this.title = 'Gig Force'
      this.gigForce = true;
      this.description = 'GigForce is a SaaS based platform to manage your off-role employees effectively interms of their activities, work order management and single-window payout along with Tax and other entities involved al-together at ease.'
    }else if(this.currentrouter == '/project-details/pay-docs'){
      this.title = 'Pay Docs'
      this.paydocs = true;
      this.description = 'The simplest way to manage your payroll & HR.Automated accurate payroll and employee self service allow you to save time and focus on your business.Access and manage your payroll from any desktop, anywhere, anytime, safely secured via SSL encryption.'
    }
    else if(this.currentrouter == '/project-details/monitor-fish'){
      this.title = 'MonitorFish'
      this.description = 'By empowering better, more precise decision-making, our digital assistant improves the overall production stability, enhances efficiency and enables you to control the farm to suit your growth plan.'
      this.monitorFish = true;
    }else if(this.currentrouter == '/project-details/level-up'){
      this.title = 'LevelUp'
      this.description = 'The Level-up App This is for HGV drivers who are working with Level-up logistics Recruitment, if you would like an account please contact us. The App is built for HGV drivers to create profiles where they can monitor their own key metrics, this includes, Mileage, Infringements, accidents."The app allows a driver to rate and feedback on the company it works for rating the fleet, facilities and operational standards with the aim of levelling-up the logistics sector'
      this.levelUp = true;
    }else if(this.currentrouter == '/project-details/goody'){
      this.title = 'Goody',
      this.goody = true;
      this.description = 'Goody is an opportunity in the online platform to choose the best shop and to buy the best product. Goody is the one and only platform which really connect the customers with the nearby vendors. And not only connecting product, it connects the relationship between the sellers and buyers. Opportunity to place the offers you envisioned will be direct to the hands, eyes, and mind of your customers.'
    }else if(this.currentrouter == '/project-details/madhatv'){
      this.title = 'Madha Tv',
      this.madhatv = true;
      this.description = 'Madha TV - To Nourish Christian faith & spiritual formation, to impart the 2000 years old Apostolic Biblical Catholic Faith!      '
    }else if(this.currentrouter == '/project-details/edwisely'){
      this.title = 'Edwisely',
      this.description = 'Edwise is Indias First AI-Powered Learning and Career Companion for Students. Using the power of Knowledge Graph and AI, we personalize and optimize learning for every student to reach their fullest potential. Major covered features are Uninterrupted learning, Daily and trending topics details, Subject based learning contents, Interact with teachers, Learning analytics, Smart assessments. Students can practice what they ve learned and be prepared for exams using our bank of questions classified by toughness.'
      this.edwisely = true;
      
    }

  }
}
