<!DOCTYPE html>
<html>
 
<head>
    <title>Welcome To GFG</title>
</head>
 
<body>
    <h2>GeeksforGeeks</h2>
    <p>This is the demo of <img> tag.</p>
    <img src=
"https://media.geeksforgeeks.org/wp-content/uploads/20210915115837/gfg3-300x300.png"
         alt="GFG image" />
</body>
 
</html>