import { Component } from '@angular/core';

@Component({
  selector: 'app-website-developement',
  templateUrl: './website-developement.component.html',
  styleUrls: ['./website-developement.component.scss']
})
export class WebsiteDevelopementComponent {

}
