import { Component } from '@angular/core';

@Component({
  selector: 'app-php-developer',
  templateUrl: './php-developer.component.html',
  styleUrls: ['./php-developer.component.scss']
})
export class PhpDeveloperComponent {

}
