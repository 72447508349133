import { Component } from '@angular/core';

@Component({
  selector: 'app-ecommerce-development',
  templateUrl: './ecommerce-development.component.html',
  styleUrls: ['./ecommerce-development.component.scss']
})
export class EcommerceDevelopmentComponent {

}
