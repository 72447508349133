<!-- Start Page Title Area -->

<div class="it-services-banner-area pb-0">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="it-services-banner-content">
                    <h1>{{title}}</h1>
                    <p>
                        {{description}}
                    </p>
                    <div class="btn-box">
                        <a routerLink="/contact" class="default-btn">Get in Touch</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="it-services-banner-image">
                    <img
                        src="assets/img/it-services-home/it-services.jpg"
                        alt="image"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
<section class="mt-4 mb-5">
    <div class="container">
        <div class="projects-wrap-content">
            <h2>OUR PORTFOLIO</h2>
        </div>
        <div class="image-grid" *ngIf="templeTourism">
            <div class="image-container">
                <img src="assets/img_tfe/project-portfolio/temple.jpg" alt="Image 4">
              </div>
            <div class="image-container">
              <img src="assets/img_tfe/portfolio/temple.png" alt="Image 1">
            </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/temple2.png" alt="Image 2">
            </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/temple4.png" alt="Image 3">
            </div>
          </div>
          <div class="image-grid" *ngIf="upajBadao">
            <div class="image-container">
                <img src="assets/img_tfe/project-portfolio/upaj1.jpg" alt="Image 4">
              </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/upaj2.jpg" alt="Image 1">
            </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/upaj3.jpg" alt="Image 2">
            </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/upaj4.jpg" alt="Image 3">
            </div>
          </div>
          <div class="image-grid" *ngIf="levelUp">
            <div class="image-container">
                <img src="assets/img_tfe/project-portfolio/levelup3.jpg" alt="Image 4">
              </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/levelup4.jpg" alt="Image 1">
            </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/levelup1.jpg" alt="Image 2">
            </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/levelup2.jpg" alt="Image 3">
            </div>
          </div>
          <div class="image-grid" *ngIf="goody">
            <div class="image-container">
                <img src="assets/img_tfe/project-portfolio/Goody-new1.jpg" alt="Image 4">
              </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/Goody-new2.jpg" alt="Image 1">
            </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/goody-new3.jpg" alt="Image 2">
            </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/Goody4.jpg" alt="Image 3">
            </div>
          </div>
          <div class="image-grid" *ngIf="gigForce">
            <div class="image-container">
                <img src="assets/img_tfe/project-portfolio/gig1.jpg" alt="Image 4">
              </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/gig2.jpg" alt="Image 1">
            </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/gig3.jpg" alt="Image 2">
            </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/gig4.jpg" alt="Image 3">
            </div>
          </div>
          <div class="image-grid" *ngIf="madhatv">
            <div class="image-container">
                <img src="assets/img_tfe/project-portfolio/mat1.jpg" alt="Image 4">
              </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/mat2.jpg" alt="Image 1">
            </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/mat3.jpg" alt="Image 2">
            </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/mat4.jpg" alt="Image 3">
            </div>
          </div>
          <div class="image-grid" *ngIf="monitorFish">
            <div class="image-container">
                <img src="assets/img_tfe/project-portfolio/monitor1.jpg" alt="Image 4">
              </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/monitor2.jpg" alt="Image 1">
            </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/monitor3.jpg" alt="Image 2">
            </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/monitor4.jpg" alt="Image 3">
            </div>
          </div>
          <div class="image-grid" *ngIf="edwisely">
            <div class="image-container">
                <img src="assets/img_tfe/project-portfolio/edwise1.jpg" alt="Image 4">
              </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/edwise2.jpg" alt="Image 1">
            </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/edwise3.jpg" alt="Image 2">
            </div>
            <div class="image-container">
              <img src="assets/img_tfe/project-portfolio/edwise4.jpg" alt="Image 3">
            </div>
          </div>
          <!-- web -->
          <div *ngIf="gigForce" class="mt-2">
            <div class="projects-wrap-content">
              <h2>Web APP</h2>
          </div>
            <div class="web-grid" *ngIf="gigForce">
              <div class="web-container">
                  <img src="assets/img_tfe/projects/gigfoce_login.jpg"  alt="Image 4">
                </div>
              <div class="web-container">
                <img src="assets/img_tfe/projects/gigfoce_agent.png" width="100%" alt="Image 1">
              </div>
              <div class="web-container">
                <img src="assets/img_tfe/projects/gig_forceseat_2.png" alt="Image 2" width="100%">
              </div>
              <div class="web-container">
                <img src="assets/img_tfe/projects/gigforce_payout.png" alt="Image 3" width="100%" >
              </div>
            </div>
          </div>

          <div *ngIf="paydocs" class="mt-2">
            
            <div class="web-grid" *ngIf="paydocs">
              <div class="web-container" style="height: 342px;">
                  <img src="assets/img_tfe/projects/paydocs-login.png" style="height: 342px;" alt="Image 4">
                </div>
              <div class="web-container" style="height: 342px;">
                <img src="assets/img_tfe/projects/paydocs-home.png" style="height: 342px;" width="100%" alt="Image 1">
              </div>
              <div class="web-container"  style="height: 342px;">
                <img src="assets/img_tfe/projects/paydocs-about.png" style="height: 342px;" alt="Image 2" width="100%">
              </div>
              
            </div>
          </div>
        
          <!-- <section class="project-details-area ptb-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="projects-details-desc">
                           
                            <div class="project-details-info">
                                <div class="single-info-box">
                                    <h4>Client</h4>
                                    <span>James Anderson</span>
                                </div>
                                <div class="single-info-box">
                                    <h4>Category</h4>
                                    <span>Network, Marketing</span>
                                </div>
                                <div class="single-info-box">
                                    <h4>Date</h4>
                                    <span>February 28, 2020</span>
                                </div>
                                <div class="single-info-box">
                                    <h4>Share</h4>
                                    <ul class="social">
                                        <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="flaticon-pinterest"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    </ul>
                                </div>
                                <div class="single-info-box">
                                    <a routerLink="/" class="default-btn">Live Preview</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

          <!-- <div class="description">
            <h2 class="description-heading">What we Done</h2>
            <p class="description-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in tellus non risus faucibus dictum a sit amet nunc. Nulla id tellus metus. Sed ut libero vel justo volutpat feugiat. Fusce vel felis id quam ultricies faucibus. In hac habitasse platea dictumst. Nam pharetra congue nisi. Nulla in ex lobortis, congue nisl vitae, sodales eros. Fusce aliquam, lorem non rutrum elementum, ante mauris consequat eros, eu posuere libero mauris vel lectus.</p>
          </div> -->
          
          
    </div>
</section>
