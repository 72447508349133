<div class="main-banner-area-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center" style="margin-top: 90px;">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <!-- <span>Digital Marketing</span> -->
                            <!-- <h1>Ring us straight away, if you could check one of these positive please!</h1> -->
                            <h1>Are you one of these?</h1>
                            <ul class="list">
                                <li><i class="flaticon-tick"></i> I have my business where paper work takes place the most</li>
                                <li><i class="flaticon-tick"></i> Auditing the past work is always hustle in my business</li>
                                <li><i class="flaticon-tick"></i> ManPower/Resouce management is hectic in my business</li>
                                <li><i class="flaticon-tick"></i> I am having a full fledged IT team, though I lag few skills but I dont want to go for hiring in full-swing</li>
                                <li><i class="flaticon-tick"></i> I have a business idea which has be developed</li>
                                <li><i class="flaticon-tick"></i> I have a masterclass plan but need POC/MVM inorder to spend much and get sharpen the idea market fit</li>
                            </ul>
                            <br>
                               <div class="banner-btn">
                                <a routerLink="/contact" class="default-btn">Contact Us</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img src="assets/img/home-three/business-analysis.gif" class="wow animate__animated animate__zoomIn" data-wow-delay="0.3s" alt="image" style="    top: -346px;
                            height: 640px;width: 640px;z-index: 10;">
                            <!-- <img src="assets/img/home-three/banner1.jpg" class="wow animate__animated animate__fadeInDown" data-wow-delay="0.6s" alt="image"> -->
                            <!-- <img src="assets/img/home-three/banner1.jpg" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image"> -->
                            <!-- <img src="assets/img/home-three/banner1.jpg" class="wow animate__animated animate__fadeInDown" data-wow-delay="0.6s" alt="image"> -->
                            <img src="assets/img/home-three/business-analysis.gif" class="wow animate__animated animate__zoomIn" data-wow-delay="0.3s" alt="image" style="top: -40px;left: -14px;">
                            <!-- <img src="assets/img/home-three/banner1.jpg" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="image"> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</div>
<section class="features-section pt-30 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Creative <span>Highlights</span></h2>
            <p>On top of the core functional elements with our engineering expertise, Techfidelite Solutions also has more flexible options to choose between to make customer life ease.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-blueprint"></i>
                    </div>
                    <h3>User Experience</h3>
                    <p>Usability testing and sticking to trend matters a lot to serve the better user experience and our engineering team ensures that at their best.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-update"></i>
                    </div>
                    <h3>Proof Of Concept</h3>
                    <p>We understand and support the startup idea in early stage, where instead of treating idea as a project, we take ownership and do our best to groom the idea and get a POC ready at the lowest price to go the idea into market and raise funding.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-security"></i>
                    </div>
                    <h3>Maintenance Friendly</h3>
                    <p>Techfidelite solutions offers wide range of maintenance plan to ensure not to bother customers as it may be a phase where they concentrate more on fitting their product market friendly and to keep going on their vision.</p>
               
                     </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-clock"></i>
                    </div>
                    <h3>Upto-Date</h3>
                    <p>Techfidelite solutions and its engineers keen to keep them upto-date on the trending, deprecation on their own domain/technology and never hesitate to scale up their expertise onto any market trending subject.</p>
               
                     </div>
            </div>
        </div>
    </div>
</section>

<section class="services-section bg-color pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Awesome</span> Services</h2>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-it"></i>
                    </div>
                    <h3>Mobile App Development</h3>
                    <p>Techfidelite Solutions is one of the best native mobile application developement in the world.</p> <br><br>
                    <a routerLink="/mobile-app-development" class="read-btn">Read More</a>
                </div>
            </div> 
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-deb0fe">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Web App Development</h3>
                    <p>Either its server-side rendering or typescript or any sort js web solution, Techfidelite Solutions is having expertise across web dev skills.</p><br>
                    <a routerLink="/web-app-development" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3>Business Consultancy</h3>
                    <p>Techfidelite Solutions helps you to automate your business process or to address any sort of hectic manual operation by means of any digital solution..</p><br>
                    <a routerLink="/business-consultancy" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fcc774">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>RaaS</h3>
                    <p>Techfidelite Solutions also offers the best skill from the market to address any specific requirement from a firm or platform to engage on a limited time or more extensively.</p>
                    <a routerLink="/raas" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-84b7fd">
                        <i class="flaticon-shopping-cart"></i>
                    </div>
                    <h3>QAaaS</h3>
                    <p>Techfidelite offers QA as a Service for various business needs as its an important phase of software deployment and ensures hassle free user experience.</p>
                    <a routerLink="/qaas" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fe929f">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>Solution Architect</h3>
                    <p>Techfidelite Solutions helps business to architect an end-to-end software system itself or just a module based on business need.</p><br>
                    <a routerLink="/business-consultancy" class="read-btn">Read More</a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<!-- <section class="audience-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="audience-image">
                    <img src="assets/img/audience.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="audience-content">
                    <h3>Engaging New <span>Audience</span> Through Smart Approach</h3>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <div class="audience-btn">
                        <a routerLink="/" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section> -->


<section class="solutions-section pb-80">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="solutions-content-area">
                    <div class="solutions-content">
                        <h3>Our <span>Featured</span> Solutions</h3>
                        <div class="bar"></div>
                        <p>Techfidelite Solutions have hands on solutions which can be customized according to business requirement. Our engineers and their expertise will make this looking so seamless.</p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon">
                            <i class="flaticon-blog"></i>
                        </div>
                        <h3>ERP Solution</h3>
                        <p>We have built many ERP modules and based on customer requirement, we can get it tweaked as a personalized solution for what business demands.</p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon bg-d3fbf9">
                            <i class="flaticon-software"></i>
                        </div>
                        <h3>CRM Solution</h3>
                        <p>Every business may choose to have different CRM for their need and thus our standarized enginnering team has a wide open solution from which any module can be choosen and customized for business requirement.</p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon bg-fce8c9">
                            <i class="flaticon-analysis"></i>
                        </div>
                        <h3>Point of Sales</h3>
                        <p>From Small, Medium to large retail and whole sale market worldwide, we created a hands on POS solution which can be used as a cloud software or as an software inside bounderies in the local network with one or more POS terminals.</p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon bg-d5e6fe">
                            <i class="flaticon-laptop"></i>
                        </div>
                        <h3>E-Commerce</h3>
                        <p>Either it is a single white labelled e-commerce solution or a multi tenant architecture or warehouse modelling, we have a master solution which can be customized on whatever the demand is.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pr-0">
                <div class="solutions-image">
                    <img src="assets/img/feature-solution.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>



<section class="counter-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>We have Completed <span>150+</span> Projects <span>Successfully</span></h2>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-check"></i>
                    </div>
                    <h3><span class="odometer" data-count="150">00</span>+</h3>
                    <p>Completed Project</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-happy"></i>
                    </div>
                    <h3><span class="odometer" data-count="80">00</span>+</h3>
                    <p>Happy Clients</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class='bx bx-globe-alt' ></i>
                    </div>
                    <h3><span class="odometer" data-count="10">00</span>+</h3>
                    <p>Geo Location / Time Zone</p>
                </div>
            </div>
          
        </div>
    </div>
</section>

<!-- <section class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Expert Team</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img1.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Alex Maxwel</h3>
                        <span>Founder</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img2.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Williams Halimton</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img3.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Juhon Dew</h3>
                        <span>CEO</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section> -->

<section class="choose-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Why <span>Choose</span> Us</h2>
            <p> We adore ourselves and would be loving to project ourselves to form a hurdle free inspirational way for the future young entrepreneurs. Being a team of tech-geek, we would be making sure about product quality and creating a happy client every time.</p>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-shared-folder"></i>
                    </div>
                    <h3>Experienced SME's</h3>
                    <p>
                        Techfidelite has people with rich technical experience along with market-proven models.
                      </p>
                </div>
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-blog"></i>
                    </div>
                    <h3>Work towards commitment</h3>
                    <p>
                        A well defined agile methodology and SME along with proper communication makes Techfidelite focusing on quality product delivery on time.
                      </p>
                </div>
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-quality"></i>
                    </div>
                    <h3>Customer centric</h3>
                    <p>
                        To create happy customers, Techfidelite plans to make use of resources most efficiently in terms of both performance and cost.
                      </p>
                </div>
                <div class="choose-content">
                    <div class="icon">
                        <i class="flaticon-target"></i>
                    </div>
                    <h3>Expertise & Leadership</h3>
                    <p>We Have A Highly Skilled And Knowledgeable Mob App ,Web App Development Team With An Extensive Understanding Of The Concept, Design, Programming, Quality, And Delivery.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="choose-image">
                    <img src="assets/img/chooseus.jpg" alt="image" style="border-radius: 50px;">
                </div>
            </div>
        </div>
    </div>
</section>


<section class="protfolio-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Impressive</span> Portfolio</h2>
            <p>Some of our great works</p>
            <div class="bar"></div>
        </div>
        <div class="row">
         
            
           
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/project-details/Gigforce"><img src="assets/img_tfe/portfolio/gigforce.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/project-details/Gigforce">GigForce</a></h3>
                        <span>Android & web</span>
                        <a routerLink="/project-details/Gigforce" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/project-details/upaj-badao"><img src="assets/img_tfe/portfolio/upaj.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/project-details/upaj-badao">Upaj Badao</a></h3>
                        <span>Android & web</span>
                        <a routerLink="/project-details/upaj-badao" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/project-details/pay-docs"><img src="assets/img_tfe/portfolio/paydocslogo.jpg" alt="image" style="height: 309px;"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/project-details/pay-docs">Pay Docs</a></h3>
                        <span>web</span>
                        <a routerLink="/project-details/pay-docs" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/project-details/monitor-fish"><img src="assets/img_tfe/portfolio/monitorfish.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/project-details/monitor-fish">MonitorFish</a></h3>
                        <span>Android & IOS</span>
                        <a routerLink="/project-details/monitor-fish" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/project-details/edwisely"><img src="assets/img_tfe/projects/edwise.jpg" alt="image" style="height: 309px;"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/project-details/edwisely">Edwisely</a></h3>
                        <span>Android & IOS</span>
                        <a routerLink="/project-details/edwisely" class="link-btn"></a>
                    </div>
                </div>
            </div>
          
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/project-details/level-up"><img src="assets/img_tfe/projects/levelup.jpg" alt="image" style="height: 309px;"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/project-details/level-up">LevelUp</a></h3>
                        <span>Android & IOS</span>
                        <a routerLink="/project-details/level-up" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/project-details/goody"><img src="assets/img_tfe/projects/goody.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/project-details/goody">Goody</a></h3>
                        <span>Android</span>
                        <a routerLink="/project-details/goody" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/project-details/madhatv"><img src="assets/img_tfe/projects/mathatv.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/project-details/madhatv">Madha Tv</a></h3>
                        <span>IOS</span>
                        <a routerLink="/project-details/madhatv" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/project-details/Temple-tourism"><img src="assets/img_tfe/projects/temple-tour.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/project-details/Temple-tourism">Temple Tourism</a></h3>
                        <span>Android & IOS</span>
                        <a routerLink="/project-details/Temple-tourism" class="link-btn"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="clients-section">
    <div class="container">
        <div class="section-title">
            <h2>What Our <span>Clients</span> Says</h2>
            <div class="bar"></div>
        </div>
        <div class="clients-slider  owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Techfidelite Solutions Pvt. Ltd has helped us with critical aspects of our product development like security , 
                    Multi Tenant architecture, Mobile app development..Their Team and Project management is agile and Timelines are always met.</p>
                <div class="clients-content">
                    <h3>Naresh Pullakandam</h3>
                    <span>Managing Director | Acsestech consulting services private limited</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>We chose to build our first professional website with Techfidelite. We are pleased with the result as we could turn all our 
                    ideas into reality. Techfidelite was able to realize a highly complex website including social media feed, webshop, 
                    search function and many more together with intuitive and robust content management.</p>
                <div class="clients-content">
                    <h3>Dominik Sarma</h3>
                    <span>Managing Director | Belyntic GmbH</span>
                </div>
            </div>
            
        </div>
        <br>
            <br>
    </div>
</section>

