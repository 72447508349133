import { Component } from '@angular/core';

@Component({
  selector: 'app-managed-it-sourcing',
  templateUrl: './managed-it-sourcing.component.html',
  styleUrls: ['./managed-it-sourcing.component.scss']
})
export class ManagedItSourcingComponent {

}
