<div class="navbar-area navbar-style-two">
    <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img src="assets/img/tfe_logo.png" alt="logo">
            </a>

            <button class="navbar-toggler" type="button">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="">
                <ul class="navbar-nav">
                    <li class="nav-item"><a routerLink="/" class="nav-link">Home </a>
                    </li>
                    <li class="nav-item"><a routerLink="/about" class="nav-link">About us</a>

                   

                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Services <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/mobile-app-development" routerLinkActive="active" class="nav-link">Mobile App Development</a>
                            </li>

                            <li class="nav-item">
                                <a routerLink="/web-app-development" routerLinkActive="active" class="nav-link">Web App Development</a>
                            </li>

                            <li class="nav-item">
                                <a routerLink="/backend-development" routerLinkActive="active" class="nav-link">Back End Development</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/customized-solutions" routerLinkActive="active" class="nav-link">Customized Solutions</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/seo-aso" routerLinkActive="active" class="nav-link">SEO and ASO</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/business-consultancy" routerLinkActive="active" class="nav-link">Business Consultancy</a>
                            </li>
                            
                            <li class="nav-item">
                                <a routerLink="/raas" routerLinkActive="active" class="nav-link">RaaS</a>
                            </li>

                            <li class="nav-item">
                                <a routerLink="/offshore-development-center" routerLinkActive="active" class="nav-link">Offshore Development Center</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/devops" routerLinkActive="active" class="nav-link">DevOps</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/qaas" routerLinkActive="active" class="nav-link">QAaaS</a>
                            </li>
                        
                        </ul>
                    </li>

                 
                    <li class="nav-item dropdown">
                        <a href="javascript:void(0)" class="nav-link " data-bs-toggle="dropdown">
                            Hire Developers <i class="bx bx-chevron-down"></i>
                        </a>
                         <div class="row dropdown-menu mega-menu"  style = "width: 400px;"role="menu">
                            <div class="row">
                                <div class="col-lg-4 col-12">
                                    <ul class="">
                                        <li class="nav-item"> <a href="javascript:void(0)"><h5 class="font-color">Mobile</h5></a></li>
                                        
                                        <li class="nav-item">
                                            <a routerLink="/android-native" routerLinkActive="active" class="nav-link">Android</a>
                                        </li>
        
                                        <li class="nav-item">
                                            <a routerLink="/ios-native" routerLinkActive="active" class="nav-link">iOS</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-lg-4 col-12">
                                    <ul class="">
                                        <li> <a href="javascript:void(0)"><h5 class="font-color">Web</h5></a></li> 
                                        <li class="nav-item">
                                            <a routerLink="/mern-stack" routerLinkActive="active" class="nav-link">Mern</a>
                                        </li>
        
                                        <li class="nav-item">
                                            <a routerLink="/mean-stack" routerLinkActive="active" class="nav-link">Mean</a>
                                        </li>
        
                                    </ul>
                                </div>
                                <div class="col-lg-4 col-12">
                                    <ul class="">
                                        <li class="ml-2"> <a href="javascript:void(0)"><h5 class="font-color">More</h5></a> </li>
                                        
                                        <li class="nav-item">
                                            <a routerLink="/php-developer" routerLinkActive="active" class="nav-link">PHP</a>
                                        </li>
        
                                        <li class="nav-item">
                                            <a routerLink="/nodejs-developer" routerLinkActive="active" class="nav-link">Node Js</a>
                                        </li>
                                        <li class="nav-item">
                                            <a routerLink="/python" routerLinkActive="active" class="nav-link">Python</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                         </div>
                   
                    </li>
                    <li class="nav-item"><a routerLink="/portfolio" class="nav-link">Portfolio</a>
                    </li>
                    <li class="nav-item"><a routerLink="/career" class="nav-link">Careers</a>
                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link" routerLinkActive="active">Contact Us</a></li>
                </ul>
          
            </div>
        </nav>
    </div>
</div>