import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';

import { HomeThreeComponent } from './components/pages/home-three/home-three.component';

import { NavbarTwoComponent } from './components/layouts/navbar-two/navbar-two.component';

import { AboutComponent } from './components/pages/about/about.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ProjectsOneComponent } from './components/pages/projects-one/projects-one.component';
import { ProjectsTwoComponent } from './components/pages/projects-two/projects-two.component';

import { ContactComponent } from './components/pages/contact/contact.component';




import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ErrorComponent } from './components/pages/error/error.component';

import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';

import { MobileAppDevelopmentComponent } from './components/pages/mobile-app-development/mobile-app-development.component';
import { WebAppDevelopmentComponent } from './components/pages/web-app-development/web-app-development.component';
import { WebsiteDevelopementComponent } from './components/pages/website-developement/website-developement.component';
import { EcommerceDevelopmentComponent } from './components/pages/ecommerce-development/ecommerce-development.component';
import { QaTestingDevelopementComponent } from './components/pages/qa-testing-developement/qa-testing-developement.component';
import { ManagedItSourcingComponent } from './components/pages/managed-it-sourcing/managed-it-sourcing.component';
import { ItStaffAugmentationComponent } from './components/pages/it-staff-augmentation/it-staff-augmentation.component';
import { MvpDevelopmentComponent } from './components/pages/mvp-development/mvp-development.component';
import { DevOpsComponent } from './components/pages/dev-ops/dev-ops.component';
import { QAaaSComponent } from './components/pages/qaaa-s/qaaa-s.component';
import { AndroidNativeComponent } from './components/pages/android-native/android-native.component';
import { IosNativeComponent } from './components/pages/ios-native/ios-native.component';
import { MernStackComponent } from './components/pages/mern-stack/mern-stack.component';
import { MeanStackComponent } from './components/pages/mean-stack/mean-stack.component';
import { PhpDeveloperComponent } from './components/pages/php-developer/php-developer.component';
import { LaravelComponent } from './components/pages/laravel/laravel.component';
import { NodejsDeveloperComponent } from './components/pages/nodejs-developer/nodejs-developer.component';
import { ProjectDetailsComponent } from './components/pages/project-details/project-details.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CareerComponent } from './components/pages/career/career.component';
import { BashitComponent } from './components/pages/bashit/bashit.component';
@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    FooterComponent,

    HomeThreeComponent,
    MobileAppDevelopmentComponent,

    NavbarTwoComponent,
  
    AboutComponent,
   
    ProjectsOneComponent,
    ProjectsTwoComponent,
    WebsiteDevelopementComponent,

    ProjectDetailsComponent,
    ContactComponent,
    WebAppDevelopmentComponent,
    EcommerceDevelopmentComponent,
    QaTestingDevelopementComponent,
    ManagedItSourcingComponent,
    ItStaffAugmentationComponent,
    MvpDevelopmentComponent,
    DevOpsComponent,
    QAaaSComponent,

    BashitComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    ErrorComponent,

    ComingSoonComponent,
    AndroidNativeComponent,
    IosNativeComponent,
    MernStackComponent,
    MeanStackComponent,
    PhpDeveloperComponent,
    LaravelComponent,
    NodejsDeveloperComponent,
    CareerComponent,





    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
