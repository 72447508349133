import { Component } from '@angular/core';

@Component({
  selector: 'app-qaaa-s',
  templateUrl: './qaaa-s.component.html',
  styleUrls: ['./qaaa-s.component.scss']
})
export class QAaaSComponent {

}
