<div class="default-shape">
       
       
    <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
    <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
    <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
   
    <div class="shape20 rotateme"><img src="assets/img_tfe/shape/shape14.svg" alt="image"></div>
</div>



<div class="default-shape">
       
       
    <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
    <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
    <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
   
    <div class="shape20 rotateme"><img src="assets/img_tfe/shape/shape14.svg" alt="image"></div>
</div>


<section class="about-section pt-100">
    <div class="container-fluid">
        <div class="section-title">
            <h2> <span>About</span> us</h2>
            
            <div class="bar"></div>
        </div>
        <div >
            
            <div>
                <div class="about-tab">
                    <h2>About Our Techfidelite</h2>
                    <p>TechFidElite solutions aim in providing complete visibility of your business products and services by providing one-touch solutions in the form of web-based and mobile application solutions. We take responsibility for providing quality and advanced software solutions for your Business products that help to reach the customers more efficiently. We at techfidelite solutions will assure on-time delivery of our services and will not compromise on service quality and support to be provided. We have people with diversified experience in the field of web and mobile application development.</p>
                    <div class="tab about-list-tab">
                        <ul class="tabs">
                            <li><a href="#">Our Vision</a></li>
                            <li><a href="#">Our Mission</a></li>
                            <li><a href="#">What we offer</a></li>
                        </ul>
                        <div class="tab_content">
                            <div class="tabs_item">
                                
                                <p>Carving present to shape fascinating future

                                    Our vision is to create a platform for masterminds and making them to help business minds for transforming their dreams into any digital means</p>
                            
                            </div>
                            <div class="tabs_item">
                                
                                <p>Work smartly & relentlessly

                                    We aim in delivering innovative and quality products on time by valuing customer needs.</p>
                            
                            </div>
                            <div class="tabs_item">
                               
                                <p>Fidelity on all aspects

                                    Wide range of world-class software services for almost all industries in the market. we also operates ourself as offshore development center.</p>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Expert Team</h2>
            <div class="bar"></div>
        </div>
        <div class="team-slider owl-carousel owl-theme" style="width: 100%;">
           
            <div class="col-lg-4 col-md-6 col-sm-6 expert client-item">
                <div class="team-item" style="height: 210px;">
                   
                </div>
                <div class="team-item">
                    <div class="icon"><img src="assets/img/team/avatar/vignesh.png" alt="Vignesh Kumar" style="  width:100%;
        height: 80%;"></div>
                    <div class="content">
                        <h3>Vignesh Kumar</h3>
                        <span>SME/CHRO</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 expert client-item">
                <div class="team-item">
                    <div class="icon"><img src="assets/img/team/avatar/umayaeswaran.png" alt="umayaeswaran" style="  width:100%;
        height: 80%;"></div>
                    <div class="content">
                        <h3>Umayaeswaran</h3>
                        <span>CEO</span>
                    </div>
                </div>
                <div class="team-item" style="height: 210px;">
                   
                </div>

            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 expert client-item">
                <div class="team-item" style="height: 210px;">
                   
                </div>
                <div class="team-item">
                    <div class="icon"><img src="assets/img/team/avatar/vengatesan.png" alt="image" style="  width:100%;
        height: 80%;"></div>
                    <div class="content">
                        <h3>Vengatesan</h3>
                        <span>Engineering Director</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 expert client-item">
                <div class="team-item" >
                   
                        <div class="icon"><img src="assets/img/team/avatar/vijay.png" style="  width:100%;
                            height: 80%;" alt="image"></div>
                        
                    
                    <div class="content">
                        <h3>Vijay Pradeep</h3>
                        <span>CFO/COO</span>
                    </div>
                </div>
                <div class="team-item" style="height: 210px;">
                   
                </div>


            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 expert client-item">
                <div class="team-item" style="height: 210px;">
                   
                </div>
                <div class="team-item">
                    <div class="icon"><img src="assets/img/team/avatar/muthu.png" alt="image" style="  width:100%;
        height: 80%;"></div>
                    <div class="content">
                        <h3>Muthu Kumar</h3>
                        <span>Senior Software Engineer</span>
                    </div>
                </div>



            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 expert client-item">
                <div class="team-item">
                   
                        <div class="icon"><img src="assets/img/team/avatar/saravanan.png" style="  width:100%;
                            height: 80%;" alt="image"></div>
                        
                    
                    <div class="content">
                        <h3>Saravanan</h3>
                        <span>CTO</span>
                    </div>
                </div>
                <div class="team-item" style="height: 210px;">
                   
                </div>


            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 expert client-item">
                <div class="team-item" style="height: 210px;">
                    
                </div>
                <div class="team-item">
                    <div class="icon"><img src="assets/img/team/avatar/sekar.png" alt="image" style="  width:100%;
        height: 80%;"></div>
                    <div class="content">
                        <h3>Gunasekaran</h3>
                        <span>Senior IOS Developer</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 expert client-item">
                <div class="team-item">
                   
                        <div class="icon"><img src="assets/img/team/avatar/karthi.png" style="  width:100%;
                            height: 80%;" alt="image"></div>
                        
                    
                    <div class="content">
                        <h3>Karthikeyan</h3>
                        <span>Senior Android Developer</span>
                    </div>
                </div>
                <div class="team-item" style="height: 210px;">
                   
                </div>


            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 expert client-item">
                <div class="team-item" style="height: 210px;">
                    
                </div>
                <div class="team-item">
                    <div class="icon"><img src="assets/img/team/avatar/saran.png" alt="image" style="  width:100%;
        height: 80%;"></div>
                    <div class="content">
                        <h3>Saran Kannan</h3>
                        <span>QA Engineer</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 expert client-item">
                <div class="team-item">
                   
                        <div class="icon"><img src="assets/img/team/avatar/kathir.png" style="  width:100%;
                            height: 80%;" alt="image"></div>
                        
                    
                    <div class="content">
                        <h3>Kathirprabhakaran</h3>
                        <span>Lead Engineer</span>
                    </div>
                </div>
                <div class="team-item" style="height: 210px;">
                   
                </div>


            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 expert client-item">
                <div class="team-item" style="height: 210px;">
                    
                </div>
                <div class="team-item">
                    <div class="icon"><img src="assets/img/team/avatar/logeshwari.png" alt="image" style="  width:100%;
        height: 80%;"></div>
                    <div class="content">
                        <h3>Logeshwari</h3>
                        <span>Senior Software Engineer</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 expert client-item">
                <div class="team-item">
                   
                        <div class="icon"><img src="assets/img/team/avatar/pari.png" style="  width:100%;
                            height: 80%;" alt="image"></div>
                        
                    
                    <div class="content">
                        <h3>Parisubalan</h3>
                        <span>Junior Android Developer</span>
                    </div>
                </div>
                <div class="team-item" style="height: 210px;">
                   
                </div>


            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 expert client-item">
                <div class="team-item" style="height: 210px;">
                    
                </div>
                <div class="team-item">
                    <div class="icon"><img src="assets/img/team/avatar/surya.png" alt="image" style="  width:100%;
        height: 80%;"></div>
                    <div class="content">
                        <h3>Surya</h3>
                        <span>Junior IOS Developer</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 expert client-item">
                <div class="team-item">
                   
                        <div class="icon"><img src="assets/img/team/avatar/kannan.png" style="  width:100%;
                            height: 80%;" alt="image"></div>
                        
                    
                    <div class="content">
                        <h3>Kannan</h3>
                        <span>Software Engineer</span>
                    </div>
                </div>
                <div class="team-item" style="height: 210px;">
                   
                </div>


            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 expert client-item">
                <div class="team-item" style="height: 210px;">
                    
                </div>
                <div class="team-item">
                    <div class="icon"><img src="assets/img/team/avatar/praveen.png" alt="image" style="  width:100%;
        height: 80%;"></div>
                    <div class="content">
                        <h3>Praveen Kumar</h3>
                        <span>Software Engineer</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 expert client-item">
                <div class="team-item">
                   
                        <div class="icon"><img src="assets/img/team/avatar/subbiah.png" style="  width:100%;
                            height: 80%;" alt="image"></div>
                        
                    
                    <div class="content">
                        <h3>Subbiah</h3>
                        <span>Software Engineer</span>
                    </div>
                </div>
                <div class="team-item" style="height: 210px;">
                   
                </div>


            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 expert client-item">
                <div class="team-item" style="height: 210px;">
                    
                </div>
                <div class="team-item">
                    <div class="icon"><img src="assets/img/team/avatar/siva.png" alt="image" style="  width:100%;
        height: 80%;"></div>
                    <div class="content">
                        <h3>Siranjeevi</h3>
                        <span>Associate Software Engineer</span>
                    </div>
                </div>
            </div>
            
    
  
  
    






        </div>

    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section> -->



<section class="clients-section pt-70">
    <div class="container">
        <div class="section-title">
            <h2>What Our <span>Clients</span> Says</h2>
            <p></p>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Techfidelite Solutions Pvt. Ltd has helped us with critical aspects of our product development like security , 
                    Multi Tenant architecture, Mobile app development..Their Team and Project management is agile and Timelines are always met.</p>
                <div class="clients-content">
                    <h3>Naresh Pullakandam</h3>
                    <span>Managing Director | Acsestech consulting services private limited</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>We chose to build our first professional website with Techfidelite. We are pleased with the result as we could turn all our 
                    ideas into reality. Techfidelite was able to realize a highly complex website including social media feed, webshop, 
                    search function and many more together with intuitive and robust content management.</p>
                <div class="clients-content">
                    <h3>Dominik Sarma</h3>
                    <span>Managing Director | Belyntic GmbH</span>
                </div>
            </div>
           
        </div>
        <br>
        <br>
    </div>
</section>
