import { Component } from '@angular/core';

@Component({
  selector: 'app-dev-ops',
  templateUrl: './dev-ops.component.html',
  styleUrls: ['./dev-ops.component.scss']
})
export class DevOpsComponent {

}
