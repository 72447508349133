import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects-two',
  templateUrl: './projects-two.component.html',
  styleUrls: ['./projects-two.component.scss']
})
export class ProjectsTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
