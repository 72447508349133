import { Component } from '@angular/core';

@Component({
  selector: 'app-it-staff-augmentation',
  templateUrl: './it-staff-augmentation.component.html',
  styleUrls: ['./it-staff-augmentation.component.scss']
})
export class ItStaffAugmentationComponent {

}
