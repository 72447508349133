<div class="default-shape">
       
       
    <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
    <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
    <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
   
    <div class="shape20 rotateme"><img src="assets/img_tfe/shape/shape14.svg" alt="image"></div>
</div>




<section class="protfolio-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Impressive</span> Portfolio</h2>
            <div class="page-title-content">
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><a routerLink="/projects-one">Portfolio</a></li>
                   
                </ul>
            </div>
            <div class="bar"></div>
        </div>
        <div class="row">
         
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/project-details/monitor-fish"><img src="assets/img_tfe/portfolio/monitorfish.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/project-details/monitor-fish">MonitorFish</a></h3>
                        <span>Android & IOS</span>
                        <a routerLink="/project-details/monitor-fish" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/project-details/upaj-badao"><img src="assets/img_tfe/portfolio/upaj.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/project-details/upaj-badao">Upaj Badao</a></h3>
                        <span>Android & web</span>
                        <a routerLink="/project-details/upaj-badao" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/project-details/Gigforce"><img src="assets/img_tfe/portfolio/gigforce.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/project-details/Gigforce">GigForce</a></h3>
                        <span>Android & web</span>
                        <a routerLink="/project-details/Gigforce" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/project-details/edwisely"><img src="assets/img_tfe/projects/edwise.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/project-details/edwisely">Edwisely</a></h3>
                        <span>Android & IOS</span>
                        <a routerLink="/project-details/edwisely" class="link-btn"></a>
                    </div>
                </div>
            </div>
          
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/project-details/level-up"><img src="assets/img_tfe/projects/levelup.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/project-details/level-up">LevelUp</a></h3>
                        <span>Android & IOS</span>
                        <a routerLink="/project-details/level-up" class="link-btn"></a>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/project-details/goody"><img src="assets/img_tfe/projects/goody.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/project-details/goody">Goody</a></h3>
                        <span>Android</span>
                        <a routerLink="/project-details/goody" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/project-details/madhatv"><img src="assets/img_tfe/projects/mathatv.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/project-details/madhatv">Madha Tv</a></h3>
                        <span>IOS</span>
                        <a routerLink="/project-details/madhatv" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/project-details/Temple-tourism"><img src="assets/img_tfe/projects/temple-tour.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/project-details/Temple-tourism">Temple Tourism</a></h3>
                        <span>Android & IOS</span>
                        <a routerLink="/project-details/Temple-tourism" class="link-btn"></a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>