
<div class="page-title-area ">
    <div class="container">
        <div class="section-title">
            <h2><span>Contact</span> us</h2>
           
            <div class="bar"></div>
        </div>
    </div>
    <div class="default-shape">
       
       
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
       
        <div class="shape20 rotateme"><img src="assets/img_tfe/shape/shape14.svg" alt="image"></div>
    </div>
   
</div>



    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d520.2005050180896!2d80.24756840616473!3d12.966222999039685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52652104cc7263%3A0xb48e87658ea62ade!2sTechfidelite%20solutions%20pvt.%20ltd.%2C!5e0!3m2!1sen!2sin!4v1688476837469!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    
            </div>
        
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-contact-box">
                    <i class="flaticon-pin"></i>
                    <div class="content-title">
                        <h3>Address</h3>
                        <a href="https://www.google.com/maps/place/Techfidelite+solutions+pvt.+ltd.,/@12.966028,80.2457576,17z/data=!3m2!4b1!5s0x3a5260a63b5a9541:0x250ccb131f7a1dd9!4m6!3m5!1s0x3a52652104cc7263:0xb48e87658ea62ade!8m2!3d12.966028!4d80.2483325!16s%2Fg%2F11h00ch1wn?entry=ttu" target="_blank"><p>Techfidelite Solutions Pvt. Ltd.,
                            3rd Floor, Greeta Techpark, 3rd St, Industrial Estate, Perungudi, Chennai, Tamil Nadu 600096</p></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-contact-box">
                    <i class="flaticon-envelope"></i>
                    <div class="content-title">
                        <h3>Email</h3>
                        <a href="mailto:info@techfidelite.in">info@techfidelite.in</a>
                       
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-contact-box">
                    <i class="flaticon-phone-call"></i>
                    <div class="content-title">
                        <h3>Phone</h3>
                        <a href="tel:+919363881992">+919363881992</a>
                     
                    </div>
                </div>
            </div>
        </div>
    </div>


<section class="contact-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="contact-text">
                    <h3>Have Any Questions About Us?</h3>
                    <p>If you have an idea, we would love to hear about it.</p>
                </div>
                <div class="contact-form">
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" name="name" id="name" class="form-control"  formControlName="name" placeholder="Name" [ngClass]="{'is-invalid':f.name.errors && isFormSubmitted}">
                            <div *ngIf="f.name.errors &&  isFormSubmitted" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Name is required</div>
                              </div>
                        </div>
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" name="email" id="email" class="form-control" formControlName="email" placeholder="Your Email" [ngClass]="{'is-invalid':f.email.errors &&  isFormSubmitted}">
                            <div *ngIf="f.email.errors &&  isFormSubmitted" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">email is required</div>
                              </div>
                        </div>
                        <div class="form-group">
                            <label>Phone Number</label>
                            <input type="number" name="mobile" id="mobile" class="form-control" formControlName="mobile" placeholder="Your phone number" [ngClass]="{'is-invalid':f.mobile.errors &&  isFormSubmitted}">
                            <div *ngIf="f.mobile.errors &&  isFormSubmitted" class="invalid-feedback">
                                <div *ngIf="f.mobile.errors.required">phone number is required</div>
                              </div>
                        </div>
                        <div class="form-group">
                            <label>Subject</label>
                            <input type="text" name="msg_subject" id="msg_subject" formControlName="msg_subject" class="form-control" placeholder="Your Subject" [ngClass]="{'is-invalid':f.msg_subject.errors &&  isFormSubmitted}">
                            <div *ngIf="f.msg_subject.errors && isFormSubmitted" class="invalid-feedback">
                                <div *ngIf="f.msg_subject.errors.required">Subject is required</div>
                              </div>
                        </div>
                        <div class="form-group">
                            <label>Message</label>
                            <textarea name="message" class="form-control" formControlName="message" id="message" cols="30" rows="6" placeholder="Your Message" [ngClass]="{'is-invalid':f.message.errors &&  isFormSubmitted}"></textarea>
                            <div *ngIf="f.message.errors && isFormSubmitted" class="invalid-feedback">
                                <div *ngIf="f.message.errors.required">Message is required</div>
                              </div>
                        </div>
                        <div class="send-btn">
                            <button type="submit" class="default-btn">Send Message</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-image">
                    <img src="assets/img/contact.jpg" alt="image" style="max-width: 80%;margin-left: 110px;">
                </div>
            </div>
        </div>
    </div>

</section>