import { Component } from '@angular/core';

@Component({
  selector: 'app-android-native',
  templateUrl: './android-native.component.html',
  styleUrls: ['./android-native.component.scss']
})
export class AndroidNativeComponent {

}
