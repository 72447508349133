<div class="default-shape">
       
       
    <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
    <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
    <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
   
    <div class="shape20 rotateme"><img src="assets/img_tfe/shape/shape14.svg" alt="image"></div>
</div>



<div class="default-shape">
       
       
    <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
    <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
    <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
    <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
   
    <div class="shape20 rotateme"><img src="assets/img_tfe/shape/shape14.svg" alt="image"></div>
</div>


<section class="about-section pt-100">
    <div class="container-fluid">
        <div class="section-title">
            <h2>Join Our Software Organization and <span>Unlock</span> Your Full Potential <span>!</span></h2>
            
            <div class="bar"></div>
           
        </div>
        <div class="container">
        <div class="about-wrap-content">
            <p class="career-content">Are you a passionate and talented software professional seeking an opportunity that will truly propel your career? Look no further! Joining our software organization will provide you with a dynamic and rewarding environment where you can thrive and make a significant impact.</p>
            <br>
            <br>
            <p class="carreer-subtitle">Here are compelling reasons why you should choose to be a part of our team:</p>
            <div class="row justify-content-center">
                <div class="col-lg-6 col-sm-6">
                    <ul class="wrap-list">
                        <li><i class="fa-solid fa-check-double"></i> Innovation and Cutting-Edge Technology</li>
                        <span>At our software organization, we are at the forefront of innovation. We embrace the latest technologies and tools to develop groundbreaking software solutions. You'll have the chance to work on exciting projects and collaborate with brilliant minds, keeping you challenged and inspired.
                        </span>
                        <li><i class="fa-solid fa-check-double"></i> Collaborative and Supportive Culture</li>
                        <span>We foster a culture of collaboration and teamwork, where diverse perspectives are valued. Our organization promotes open communication and encourages everyone to contribute their ideas. You'll be part of a supportive community, where mentoring and continuous learning opportunities are readily available.</span>
                        <li><i class="fa-solid fa-check-double"></i> Career Growth and Development</li>
                        <span>We are committed to helping our employees grow both professionally and personally. Joining our organization means access to a wide range of training programs, workshops, and conferences that will enhance your skills and expertise. We provide a clear career progression path, acknowledging and rewarding your achievements along the way.</span>
                        <li><i class="fa-solid fa-check-double"></i> Impactful Projects</li>
                        <span>As a member of our software organization, you'll have the chance to work on meaningful and impactful projects. Whether it's developing software for healthcare, finance, or environmental sustainability, your work will contribute to solving real-world problems and making a positive difference in people's lives.</span>
                    </ul>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <ul class="wrap-list">
                        <li><i class="fa-solid fa-check-double"></i>Work-Life Balance</li>
                        <span>We understand the importance of maintaining a healthy work-life balance. Our organization promotes flexible work arrangements, allowing you to manage your professional responsibilities while tending to personal commitments. We value your well-being and prioritize creating an environment where you can thrive both personally and professionally.</span>
                        <li><i class="fa-solid fa-check-double"></i>Competitive Compensation and Benefits</li>
                        <span>We believe in recognizing and rewarding talent. As part of our organization, you can expect a competitive compensation package, including salary, bonuses, and benefits tailored to meet your needs. We also offer perks such as health insurance, retirement plans, and additional wellness programs.</span>
                        <li><i class="fa-solid fa-check-double"></i> Diversity and Inclusion</li>
                        <span>We are proud of our diverse and inclusive workplace. We celebrate differences and believe that a variety of perspectives strengthens our organization. Regardless of your background, gender, or ethnicity, you will be welcomed and valued for your unique contributions.</span>
                        <li><i class="fa-solid fa-check-double"></i> Positive Reputation</li>
                        <span>Our software organization has built a strong reputation in the industry for delivering exceptional software solutions and maintaining high standards of professionalism. Joining our team will not only enhance your own professional brand but also provide you with opportunities to connect and collaborate with top-tier clients and partners.</span>
                    </ul>
                </div>
            </div>
            <p class="career-content2">By joining our software organization, you'll be stepping into a world of endless possibilities. Your skills and expertise will be nurtured, and your career aspirations will be realized. Don't miss out on this incredible opportunity to join a team that values your growth and success. Apply now and embark on an exciting journey with us!</p>
        </div>


    </div>



            
        <div >
                
            <div>
                <!-- <div class="section-title pt-100">
                    <h2> <span>Why work with </span>us?</h2>
                    
                   
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-contact-box">
                            <i class="flaticon-pin"></i>
                            <div class="content-title">
                                <h3>Address</h3>
                                <a href="#"><p>Techfidelite Solutions Pvt. Ltd.,
                                   </p></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-contact-box">
                            <i class="flaticon-envelope"></i>
                            <div class="content-title">
                                <h3>Email</h3>
                                <a href="mailto:info@techfidelite.in">info@techfidelite.in</a>
                               
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                        <div class="single-contact-box">
                            <i class="flaticon-phone-call"></i>
                            <div class="content-title">
                                <h3>Phone</h3>
                                <a href="tel:+919363881992">+919363881992</a>
                             
                            </div>
                        </div>
                    </div>
                </div> -->
                    <br>
                    <br>
                    <div class="container">
                    <div class="contact-form">
                        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input type="text" name="name" id="name" class="form-control"  formControlName="name" placeholder="Name" [ngClass]="{'is-invalid':f.name.errors && isFormSubmitted}">
                                        <div *ngIf="f.name.errors &&  isFormSubmitted" class="invalid-feedback">
                                            <div *ngIf="f.name.errors.required">Name is required</div>
                                          </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input type="email" name="email" id="email" class="form-control" formControlName="email" placeholder="Your Email" [ngClass]="{   'is-invalid':
                                        f.email.errors && (f.email.touched || isFormSubmitted)}">
                                        <div  *ngIf="f.email.errors && (f.email.touched || isFormSubmitted)" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.pattern">
                       Please enter valid email address.
                    </div>
                    <div *ngIf="f.email.errors.minlength">
                      Email must be atleast 5 digits Long.
                   </div>
                                          </div>
                                    </div>
                                </div>
                              
                                
                                <div class="form-group">
                                    <label>Phone Number</label>
                                    <input type="text" name="mobile" id="mobile" class="form-control" formControlName="mobile" placeholder="Your phone number" [ngClass]="{'is-invalid':f.mobile.errors &&(f.mobile.touched ||  isFormSubmitted)}">
                                    <div *ngIf="f.mobile.errors && (f.mobile.touched || isFormSubmitted)" class="invalid-feedback">
                                        <div *ngIf="f.mobile.errors.required">Phone number is required</div>
                                        <div *ngIf="f.mobile.errors.pattern">Number should be allowed.</div>
                                        <div *ngIf="f.mobile.errors.minlength">Phone number must be at least 10 digits long.</div>
                                        <div *ngIf="f.mobile.errors.maxlength">Phone number can be max 10 digits long.</div>
                                      </div>
                                </div>
                               
                                <div class="form-group" style="    display: flex;
                                ">
                                    
                                    <input type="file" #fileInput style="display: none;" formControlName="resume"
                                      (change)="onFileSelected($event)">
                                      <i class='bx bx-upload' style="
                                      font-size: 30px;
                                      padding-top: 10px;
                                  "></i><button type="button" class="resumebutton" (click)="fileInput.click()">Upload your resume</button>
                                    <p style="    font-weight: bold;
                                    text-align: justify;
                                    margin-top: 15px;
                                    ">{{fileName}}</p>
                                </div>
                            </div>
                     
                            <div class="send-btn">
                                <button type="submit" class="default-btn">Apply</button>
                            </div>
                        </form>
                    </div>

                </div>
                <br>
                <br>

                    </div>
                    </div>
                    </div>

</section>