import { Component } from '@angular/core';

@Component({
  selector: 'app-laravel',
  templateUrl: './laravel.component.html',
  styleUrls: ['./laravel.component.scss']
})
export class LaravelComponent {

}
