import { Component } from '@angular/core';


@Component({
  selector: 'app-mobile-app-development',
  templateUrl: './mobile-app-development.component.html',
  styleUrls: ['./mobile-app-development.component.scss']
})
export class MobileAppDevelopmentComponent {

}
