import { Component } from '@angular/core';

@Component({
  selector: 'app-web-app-development',
  templateUrl: './web-app-development.component.html',
  styleUrls: ['./web-app-development.component.scss']
})
export class WebAppDevelopmentComponent {

}
