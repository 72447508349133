<div class="it-services-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="it-services-banner-content">
                    <h1>SEO and ASO</h1>
                    <p>
                        Search Engine Optimization(SEO) and App Store Optimization(ASO) are important tools for marketting and Techfidelite Solutions also ensure to help the business to get this done in order to have more visibility and reach to the target audience. Techfidelite Solutions also help the business to run campaign, gamification and more events inorder to extend the product/business visibility beyond boundaries.
                        <div class="btn-box">
                        <a routerLink="/contact" class="default-btn">Get in Touch</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="it-services-banner-image">
                    <img
                        src="assets/img/it-services-home/seo.jpg"
                        alt="image"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
 <div class="features-style-area pb-2">
    <div class="container">
        <div class="features-style-inner-box">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="features-style-card text-center">
                        <div class="features-image">
                            <img src="assets/fonts/seo.jpg" width="122px" height="122px" alt="image">
                        </div>
                        <div class="features-content">
                            <h3>SEO</h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-style-card text-center">
                        <div class="features-image">
                            <img src="assets/fonts/aso.webp" width="122px" height="122px" alt="image">
                        </div>
                        <div class="features-content">
                            <h3>ASO</h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-style-card text-center">
                        <div class="features-image">
                            <img src="assets/fonts/marketing.jpg" width="122px" height="122px" alt="image">
                        </div>
                        <div class="features-content">
                            <h3>Marketting Campaign</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="about-wrap-area pt-110 pb-110">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-wrap-image">
                    <img src="assets/img/it-services-home/about.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-wrap-content">
                    <h3>Technology Used With Mobile Application</h3>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <ul class="wrap-list">
                                <li><i class="fa-solid fa-check-double"></i>Manual Testing</li>
                                <li><i class="fa-solid fa-check-double"></i>API Testing</li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <ul class="wrap-list">
                                <li><i class="fa-solid fa-check-double"></i>Automation Testing Selenium</li>    
                                <li><i class="fa-solid fa-check-double"></i>Appium</li>
                                <li><i class="fa-solid fa-check-double"></i>Database Testing</li>
                            </ul>
                        </div>
                    </div>
                 
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- <section class="projects-wrap-area ">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-4 col-md-12">
                <div class="projects-wrap-content">
                    <span>RECENT PROJECTS</span>
                    <h2>OUR PORTFOLIO</h2>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="projects-wrap-slides owl-carousel owl-theme">
                    <div class="single-projects-card">
                        <a href="#"><img src="assets/img/why-choose-us/krishna-mobile.webp" alt="image"></a>

                        <div class="projects-content">
                            <span>Design/Idea</span>
                            <h3><a href="#">Gigforce</a></h3>
                            <p>Best solution for Emplyees</p>
                        </div>
                    </div>

                    <div class="single-projects-card">
                        <a href="#"><img src="assets/img/why-choose-us/bee-mobile.webp" alt="image"></a>

                        <div class="projects-content">
                            <span>Design/Development</span>
                            <h3><a href="#">Azerpost</a></h3>
                            <p>Azebazian post Application</p>
                        </div>
                    </div>

                    <div class="single-projects-card">
                        <a href="#"><img src="assets/img/why-choose-us/accopedia-mobile.webp" alt="image"></a>

                        <div class="projects-content">
                            <span>Design</span>
                            <h3><a href="#">Post Cargo</a></h3>
                            <p>Postal Services</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- <section class="testimonials-wrap-area ptb-110">
    <div class="container">
        <div class="section-title-style-two">
            <div class="content">
                <h2>What People Say After Using Our Services</h2>
            </div>

        </div>
        <div class="testimonials-wrap-slides owl-carousel owl-theme">
            <div class="single-testimonials-card">
                <p >My experience with Techfidelite software solutions has been
                    very pleasing. Worked with them on multiple projects and had a hassle free experience
                    till date. They quickly respond to change requests and they are awesome.

                </p>
                <div class="client-info d-flex align-items-center justify-content-center">
                    <img src="assets/img/author1.jpg" alt="image">

                    <div class="title">
                        <h3>Ansul jain</h3>
                        <span>Bangalore</span>
                    </div>
                </div>
            </div>

            <div class="single-testimonials-card">
                <p >Techfidelite helped Bee to shape up the on demand service
                    application . The
                    team contribution
                    and client engagement is top notch . I would highly recommend techfidelite to others
                    for digitalization of their businesses</p>
                <div class="client-info d-flex align-items-center justify-content-center">
                    <img src="assets/img/author2.jpg" alt="image">

                    <div class="title">
                        <h3>Selva kumar</h3>
                        <span>Marketing</span>
                    </div>
                </div>
            </div>

            <div class="single-testimonials-card">
                <p >Good live support with projects. Requirement gathering and
                    understanding of projects are good. You can expect commitment from them.</p>
                <div class="client-info d-flex align-items-center justify-content-center">
                    <img src="assets/img/author3.jpg" alt="image">

                    <div class="title">
                        <h3>Roshini</h3>
                        <span>Designer</span>
                    </div>
                </div>
            </div>
            <div class="single-testimonials-card">
                <p >Good live support with projects. Requirement gathering and
                    understanding of projects are good. You can expect commitment from them.</p>
                <div class="client-info d-flex align-items-center justify-content-center">
                    <img src="assets/img/author3.jpg" alt="image">

                    <div class="title">
                        <h3>Surya</h3>
                        <span>Designer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="testimonials-wrap-map">
        <img src="assets/img/it-services-home/map.png" alt="image">
    </div>
</section> -->
<!-- <section class="faq-area ptb-110">
    <div class="container">
        <div class="row">
             <div class="col-lg-5 col-md-12">
                <div class="faq-content">
                    <div class="section-title">
                        <div class="content">
                            <span>Ask Question</span>
                            <h2>Frequently Asked Questions</h2>
                            </div>
                    </div>

                    <div class="faq-image">
                        <img src="assets/img/faq.png" alt="image">
                    </div>
                </div>
            </div> 

            <div class="col-lg-7 col-md-12">    
                <div class="faq-accordion">
                    <ul class="accordion">
                         
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                What are the advantages of working with an outsourced QA and Testing services Team?
                            </a>
                            <p class="accordion-content">Working with a committed group of software testers and engineers has several advantages. As you don't need to spend on infrastructure, resources, the employment process, or other time-consuming business processes, it is cost-effective.</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                What is the average size of your testing team?</a>
                            <p class="accordion-content">Depending on the client's needs and the project scope, our testing services teams might vary in size from 2 to 20.<p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Would I have to test the app or website on my own?</a>
                            <p class="accordion-content">You certainly can if you want to. We offer QA testing services to all of our clients, ensuring that the app or website is wholly tested at the end and after each development module is completed. However, if you want to have a look for yourself, we are pleased to comply.<p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                What differentiates Quality Assurance from testing?
                            </a>
                            <p class="accordion-content">Both quality assurance and software testing have the same goal of creating a bug-free, functional application. It's sometimes difficult for businesses to understand. The key difference between the two is that. In contrast, software testing involves techniques and procedures to find software bugs. Quality assurance is a process to ensure that the project meets the expectations of the stakeholders.</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                How Do You Offer QA Testing Services?</a>
                            <p class="accordion-content">Techfidelite aims to meet the expectations and needs of its clients. We have been in the business for more than five years, and our team of QA specialists ensures that the final product is flawless and up to the highest standards. Our team offers QA and software testing services throughout product development..<p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                How can a QA testing company benefit my company?
                            </a>
                            <p class="accordion-content">Companies may develop a positive reputation and fulfill the needs and expectations of their customers with the help of QA testing companies like Techno Tackle. Providing consistent, high-quality services will help you gain the trust of your clients and maintain your ability to compete in the market. Our QA assurance services team joins the project early on to ensure that the finished product meets your expectations, standards, and functions without a problem.</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                What is the test plan? Will your software testing company give me a test plan?
                            </a>
                            <p class="accordion-content">We will be happy to give you the test plan you might need for your software testing project. The test plan includes all the instructions and specifications for testing, including the time required for each type of testing, responsibility, critical deliverables, and more.<p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>                              
                                Are there any additional fees for software testing & quality analysis services?
                            </a>
                            <p class="accordion-content">No, We'll discuss the project's total cost in our initial meeting. There won't be any additional fees that our customers are responsible for, and we'll stick to that pricing.<p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>                              
                                Why should I choose Techfidelite for QA Testing Services?</a>
                            <p class="accordion-content">When you employ Techfidelite for software testing and Quality Assurance services, 100% of the outcomes are guaranteed. The QA team uses cutting-edge tools and testing methods to meet your unique company requirements to produce the best and bug-free products. We employ tools like Selenium WebDriver, HP Loadrunner, QuickTest Professional, Silk Test, Apache JMeter, and others to ensure the accuracy of our QA software testing services. They use advanced technologies to test your website, program, and application before providing you with a bug-free solution<p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>                              
                                Why should I choose Techfidelite for QA Testing Services?
                            </a>
                            <p class="accordion-content">When you employ Techfidelite for software testing and Quality Assurance services, 100% of the outcomes are guaranteed. The QA team uses cutting-edge tools and testing methods to meet your unique company requirements to produce the best and bug-free products. We employ tools like Selenium WebDriver, HP Loadrunner, QuickTest Professional, Silk Test, Apache JMeter, and others to ensure the accuracy of our QA software testing services. They use advanced technologies to test your website, program, and application before providing you with a bug-free solution.  </p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>                              
                                Do you offer automated testing services?
                            </a>
                            <p class="accordion-content">Yes, we have a specialized staff of ISTQB-qualified testers who provide exceptional automated testing services to assure quality in all areas.</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>                              
                                Do you offer proper desktop and online application testing services?
                            </a>
                            <p class="accordion-content">TechFidelite offers hassle-free desktop application testing to assure product quality in all aspects. We also provide best-in-class web application testing services to meet a wide range of corporate requirements. You can contact us, and we will give you an appropriate solution based on your needs.</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>                              
                                How much experience does your team have in software testing and Quality Assurance services?
                            </a>
                            <p class="accordion-content">Our Testing and Quality Assurance team holds more than five years of experience. They have worked in various businesses throughout their careers, managing simple and complicated projects. Techno Tackle is the best option if you're looking for one of the trusted QA testing services.</p>
                        </li>
                  
                    </ul>
                </div>
            </div>
        </div>

        <div class="faq-contact">
            <div class="section-title">
                <div class="content">
                    <span>Message Us</span>
                    <h2>Do You Have Any Questions</h2>
                </div>
            </div>

            <div class="faq-contact-form">
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Phone">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                                <textarea name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Your Message"></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="btn btn-primary">Send Message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<section class="team-area ptb-110">
    <div class="container">
        <div class="section-title">
            <div class="content">
                <h2>Qa Testing Services - Chennai</h2>
                <p>We assure you that your application can function in various databases, browsers, operating systems, hardware, networks, and mobile devices. Our expertise may perform any compatibility testing you desire. We used to double-check the final product's performance against predetermined parameters.We have provided various industries with the best software testing and QA services, including functional testing, Performance testing, security testing, and test automation. Our skilled and experienced engineers and technicians use AI-powered tools and platforms. With the speed and scale of Agile, we provide the best-automated testing services to support quality. We deliver the best standard outsourcing services for software testing and quality assurance in India.</p>
            </div>

            <div class="image">
                <img src="assets/img/section-title/section-title4.png" alt="image">
            </div>
        </div>

  
    </div>

    <div class="shape13"><img src="assets/img/shape/shape13.svg" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape/shape13.svg" alt="image"></div>
</section> -->