import { Component } from '@angular/core';

@Component({
  selector: 'app-mvp-development',
  templateUrl: './mvp-development.component.html',
  styleUrls: ['./mvp-development.component.scss']
})
export class MvpDevelopmentComponent {

}
