import { Component } from '@angular/core';

@Component({
  selector: 'app-mean-stack',
  templateUrl: './mean-stack.component.html',
  styleUrls: ['./mean-stack.component.scss']
})
export class MeanStackComponent {

}
