<section class="footer-section pt-100 pb-70">
    <div class="container">
       
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>About Us</h3>
                    </div>
                    <p>TechFidElite solutions aim in providing complete visibility of your business products and services by providing one-touch solutions in the form of web-based and mobile application solutions.</p>
                    <ul class="footer-social">
                        <li><a href="https://in.linkedin.com/company/techfidelite-solutions-pvt-ltd" target="_blank"><i class='bx bxl-linkedin' ></i></a></li>
                        <li><a href="https://www.google.com/search?q=techfidelite&oq=techfidelite&aqs=chrome.0.69i59l4j0i546j69i60j69i61j69i60.11992j0j7&sourceid=chrome&ie=UTF-8" target="_blank"><i class='bx bxl-google'></i></a></li>
                        <li><a href="https://www.glassdoor.co.in/Location/Techfidelite-Solutions-Chennai-Location-EI_IE7591824.0,22_IL.23,30_IC2833209.htm" target="_blank"><img src="assets/img/glassdoor.png" alt="image" style="
                            width: 36%;
                            margin-top: -5px;
                        "></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Hire Developers</h3>
                    </div>
                    <ul class="footer-quick-links">
                       
                        
                        <li><a routerLink="/android-native">Hire Android  Developers</a></li>
                        <li><a routerLink="/ios-native">Hire iOS Developers</a></li>
                        <li><a routerLink="/mean-stack">Hire Mean Developers</a></li>
                        <li><a routerLink="/mern-stack">Hire Mern Developers</a></li>
                        <li><a routerLink="/python">Hire Python Developers</a></li>
                        <li><a routerLink="/nodejs-developer">Hire NodeJS Developers</a></li>
                        <li><a routerLink="/php-developer">Hire PHP Developers</a></li>
                        
                     
                        
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Our services</h3>
                    </div>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/mobile-app-development">Mobile App Development</a></li>
                        <li><a routerLink="/web-app-development">Web App Development</a></li>
                        <li><a routerLink="/backend-development">BackEnd Development</a></li>
                        <li><a routerLink="/customized-solutions">Customized Solutions</a></li>
                        <li><a routerLink="/seo-aso">SEO and ASO</a></li>
                        <li><a routerLink="/business-consultancy">Business Consultancy</a></li>
                        <li><a routerLink="/raas">RaaS</a></li>
                        <li><a routerLink="/offshore-development-center">Offshore Development Center</a></li>
                        <li><a routerLink="/devops">DevOps</a></li>
                        <li><a routerLink="/qaas">QAaaS</a></li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Contact</h3>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-phone-call"></i>
                        <h3>Phone</h3>
                        <span><a href="tel:+919363881992">+919363881992</a></span>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-envelope"></i>
                        <h3>Email</h3>
                        <span><a href="mailto:info@techfidelite.in">info@techfidelite.in</a></span>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-pin"></i>
                        <h3>Address</h3>
                        <span><a href="https://www.google.com/maps/place/Techfidelite+solutions+pvt.+ltd.,/@12.966028,80.2457576,17z/data=!3m2!4b1!5s0x3a5260a63b5a9541:0x250ccb131f7a1dd9!4m6!3m5!1s0x3a52652104cc7263:0xb48e87658ea62ade!8m2!3d12.966028!4d80.2483325!16s%2Fg%2F11h00ch1wn?entry=ttu" target="_blank">Techfidelite Solutions Pvt. Ltd.,<br>
                            3rd Floor, Greeta Techpark, 3rd St, Industrial Estate, Perungudi, Chennai, Tamil Nadu 600096</a></span>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</section>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-area-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>© 2019-2023 Techfidelite Solutions PVT LTD. All Rights Reserved.</p>
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="go-top"><i class="bx bx-chevron-up"></i><i class="bx bx-chevron-up"></i></div>

<div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <div class="sidebar-about-area">
            <div class="title">
                <h2>About Us</h2>
                <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
            </div>
        </div>
        <div class="sidebar-contact-feed">
            <h2>Contact</h2>
            <div class="contact-form">
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="name" id="name" class="form-control" placeholder="Your Name">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Your Subject">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" class="form-control" id="message" cols="30" rows="6" placeholder="Your Message"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="send-btn">
                                <button type="submit" class="send-btn-one">Send Message</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="sidebar-contact-area">
            <div class="contact-info">
                <div class="contact-info-content">
                    <h2>
                        <a href="tel:+0881306298615">+088 130 629 8615</a>
                        <span>OR</span>
                        <a href="mailto:hello@avrax.com">hello@avrax.com</a>
                    </h2>
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-pinterest"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <span class="close-btn sidebar-modal-close-btn">
            <i class="flaticon-cancel"></i>
        </span>
    </div>
</div>