import { Component } from '@angular/core';

@Component({
  selector: 'app-mern-stack',
  templateUrl: './mern-stack.component.html',
  styleUrls: ['./mern-stack.component.scss']
})
export class MernStackComponent {

}
